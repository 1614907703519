<template>
  <div class="Personal_Info">
    <div class="area">
      <a-row
        type="flex"
        justify="space-between"
        style="margin:20px 0px;font-size:16px;color:#333333;"
      >
        <a-col :span="8">
          <span class="border-r zIndex" style="padding:0px 10px;"
            >原始简历</span
          >
        </a-col>
      </a-row>
      <a-row style="width:92%;margin:0px auto;">
        <a-col :lg="{ span: 12 }" :xxl="{ span: 8 }"
          ><span class="titleName">员工号</span>10002075</a-col
        >
        <a-col :lg="{ span: 12 }" :xxl="{ span: 8 }"
          ><span class="titleName">入职时间</span>2020-01-03</a-col
        >
        <a-col :lg="{ span: 12 }" :xxl="{ span: 8 }"
          ><span class="titleName">转正时间</span></a-col
        >
        <a-col :lg="{ span: 12 }" :xxl="{ span: 8 }"
          ><span class="titleName">工龄</span>0个月</a-col
        >
        <!-- <a-col :lg="{ span: 12}" :xxl="{ span: 8}"><span class="titleName">员工号</span></a-col> -->
        <a-col :lg="{ span: 12 }" :xxl="{ span: 8 }"
          ><span class="titleName">个人邮箱</span>1475826735@qq.com</a-col
        >
        <a-col :lg="{ span: 12 }" :xxl="{ span: 8 }"
          ><span class="titleName">状态</span>试用期</a-col
        >
        <a-col :lg="{ span: 12 }" :xxl="{ span: 8 }"
          ><span class="titleName">部门主管</span>
          <a-tooltip placement="topLeft" title="Prompt Text" trigger="click">
            <span>点击查看</span>
          </a-tooltip>
        </a-col>
        <a-col :lg="{ span: 12 }" :xxl="{ span: 8 }"
          ><span class="titleName">创建人</span>施露/2020-01-03 15:30:43</a-col
        >
        <a-col :lg="{ span: 12 }" :xxl="{ span: 8 }"
          ><span class="titleName">紧急联系人</span></a-col
        >
        <a-col :lg="{ span: 12 }" :xxl="{ span: 8 }"
          ><span class="titleName">紧急联系人关系</span></a-col
        >
        <a-col :lg="{ span: 12 }" :xxl="{ span: 8 }"
          ><span class="titleName">紧急联系人电话</span></a-col
        >
        <a-col :lg="{ span: 12 }" :xxl="{ span: 8 }"
          ><span class="titleName">是否为教师</span>否</a-col
        >
        <a-col :lg="{ span: 12 }" :xxl="{ span: 8 }"
          ><span class="titleName">是否为财务负责人</span>否</a-col
        >
        <a-col :lg="{ span: 12 }" :xxl="{ span: 8 }"
          ><span class="titleName">是否为部门负责人</span>否</a-col
        >
        <a-col :lg="{ span: 12 }" :xxl="{ span: 8 }"
          ><span class="titleName">是否为虚拟人员</span>否</a-col
        >
        <a-col :lg="{ span: 24 }" :xxl="{ span: 16 }"
          ><span class="titleName">备注</span>详细+备注</a-col
        >
      </a-row>

      <a-row type="flex" justify="space-between" class="liner">
        <a-col :span="8">
          <span class="border-r zIndex" style="padding:0px 10px;"
            >合同信息</span
          >
        </a-col>
        <a-col :span="4" style="text-align:right;">
          <span class="zIndex" style="font-size: 14px;">
            <i class="iconfont icon-bianji" v-if="showLoop[1].operation"></i>
            <a-icon
              :type="showLoop[1].bool ? 'down' : 'up'"
              @click="boolFn(1)"
            />
          </span>
        </a-col>
      </a-row>
      <div class="fieldset" v-show="showLoop[1].bool">
        <span class="fieldset-title">合同概况</span>
        <a-row style="margin:10px 0px;">
          <a-col :lg="{ span: 12 }" :xxl="{ span: 8 }"
            ><span class="titleName">合同类型</span>10002075</a-col
          >
          <a-col :lg="{ span: 12 }" :xxl="{ span: 8 }"
            ><span class="titleName">合同开始日期</span>2020-01-03</a-col
          >
          <a-col :lg="{ span: 12 }" :xxl="{ span: 8 }"
            ><span class="titleName">合同结束日期</span></a-col
          >
          <a-col :lg="{ span: 12 }" :xxl="{ span: 8 }"
            ><span class="titleName">合同期限</span>0个月</a-col
          >
          <a-col :lg="{ span: 12 }" :xxl="{ span: 8 }"
            ><span class="titleName">合同续签次数</span>10002075</a-col
          >
          <a-col :lg="{ span: 12 }" :xxl="{ span: 8 }"
            ><span class="titleName">合同主体</span>2020-01-03</a-col
          >
          <a-col :lg="{ span: 12 }" :xxl="{ span: 8 }"
            ><span class="titleName">经办人</span></a-col
          >
        </a-row>
      </div>
      <a-row type="flex" justify="space-between" class="liner">
        <a-col :span="8">
          <span class="border-r zIndex" style="padding:0px 10px;"
            >个人材料</span
          >
        </a-col>
        <a-col :span="4" style="text-align:right;">
          <span class="zIndex" style="font-size: 14px;">
            <i
              class="iconfont icon-bianji"
              @click="operationFn(2)"
              v-if="showLoop[2].operation"
            ></i>
            <a-icon
              :type="showLoop[2].bool ? 'down' : 'up'"
              @click="boolFn(2)"
            />
          </span>
        </a-col>
      </a-row>
      <div class="box" v-show="showLoop[2].bool">
        <div class="imgArea" @click="detailsFn()">
          <a-icon
            type="close-circle"
            class="delBtn ant-menu-item-selected"
            @click.stop="delFn()"
            v-show="showLoop[2].operation == 2"
          />
          <img :src="__USER__.Avatar" />
          <p>个人照片</p>
        </div>
        <div class="imgArea">
          <a-icon
            type="close-circle"
            class="delBtn ant-menu-item-selected"
            v-show="showLoop[2].operation == 2"
          />
          <img
            src="https://smart-resource.sikegroup.com/FjC9MH5-E1Evjm4Enu43mbgyXz0y"
          />
          <p>入职登记表</p>
        </div>
      </div>
      <a-row type="flex" justify="space-between" class="liner">
        <a-col :span="8">
          <span class="border-r zIndex" style="padding:0px 10px;"
            >测评中心</span
          >
        </a-col>
        <a-col :span="4" style="text-align:right;">
          <span class="zIndex" style="font-size: 14px;">
            <i
              class="iconfont icon-bianji"
              @click="operationFn(3)"
              v-if="showLoop[3].operation"
            ></i>
            <a-icon
              :type="showLoop[3].bool ? 'down' : 'up'"
              @click="boolFn(3)"
            />
          </span>
        </a-col>
      </a-row>
      <div class="fieldset" v-show="showLoop[3].bool">
        <span class="fieldset-title">测试项目</span>
        <a-row style="margin:10px 0px;">
          <a-col :lg="{ span: 12 }" :xxl="{ span: 8 }"
            ><span class="titleName">测评名称</span>10002075</a-col
          >
          <a-col :lg="{ span: 12 }" :xxl="{ span: 8 }"
            ><span class="titleName">测评课程</span>2020-01-03</a-col
          >
          <a-col :lg="{ span: 12 }" :xxl="{ span: 8 }"
            ><span class="titleName">测评时间</span></a-col
          >
          <a-col :lg="{ span: 12 }" :xxl="{ span: 8 }">
            <span class="titleName">测评结果</span>
            <a class="inlineBtn">查看详情 <a-icon type="file-search"/></a>
          </a-col>
        </a-row>
      </div>
      <a-row type="flex" justify="space-between" class="liner">
        <a-col :span="8">
          <span class="border-r zIndex" style="padding:0px 10px;"
            >问卷调查</span
          >
        </a-col>
        <a-col :span="4" style="text-align:right;">
          <span class="zIndex">
            <i class="iconfont icon-bianji" v-if="showLoop[4].operation"></i>
            <a-icon
              :type="showLoop[4].bool ? 'down' : 'up'"
              @click="boolFn(4)"
            />
          </span>
        </a-col>
      </a-row>
      <div class="box" v-show="showLoop[4].bool">
        <a class="inlineBtn" v-for="k in 6" :key="k"
          >skim系统使用调查问卷{{ k }} &emsp;<a-icon type="file-search"
        /></a>
      </div>
      <a-row type="flex" justify="space-between" class="liner">
        <a-col :span="8">
          <span class="border-r zIndex" style="padding:0px 10px;"
            >其他附件</span
          >
        </a-col>
        <a-col :span="4" style="text-align:right;">
          <span class="zIndex" style="font-size: 14px;">
            <i
              class="iconfont icon-bianji"
              @click="operationFn(5)"
              v-if="showLoop[5].operation"
            ></i>
            <a-icon
              :type="showLoop[5].bool ? 'down' : 'up'"
              @click="boolFn(5)"
            />
          </span>
        </a-col>
      </a-row>
      <div class="box" v-show="showLoop[5].bool">
        <div class="imgArea" @click="detailsFn()">
          <a-icon
            type="close-circle"
            class="delBtn ant-menu-item-selected"
            @click.stop="delFn()"
            v-show="showLoop[5].operation == 2"
          />
          <img :src="__USER__.Avatar" />
          <p>自能导购</p>
        </div>
        <div class="imgArea">
          <a-icon
            type="close-circle"
            class="delBtn ant-menu-item-selected"
            v-show="showLoop[5].operation == 2"
          />
          <img
            src="https://smart-resource.sikegroup.com/FjC9MH5-E1Evjm4Enu43mbgyXz0y"
          />
          <p>万能码</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Personal_Info',
  data () {
    return {
      showLoop: [
        { bool: false, operation: false },
        { bool: true, operation: false },
        { bool: true, operation: true },
        { bool: true, operation: false },
        { bool: true, operation: false },
        { bool: true, operation: true }
      ]
    }
  },
  mounted () {},
  methods: {
    boolFn (index) {
      this.showLoop[index].bool = !this.showLoop[index].bool
    },
    operationFn (index) {
      if (this.showLoop[index].operation == 2) {
        this.showLoop[index].operation = true
      } else {
        this.showLoop[index].operation = 2 //2为可操作
      }
    },
    delFn () {
      console.log('删除')
    },
    detailsFn () {
      console.log('详情')
    }
  }
}
</script>
<style lang="less" scoped>
.Personal_Info {
  font-size: 14px;
  color: #333333;
  .area {
    // a{
    //     color: #999999;
    //     text-decoration: none;
    // }
    .liner {
      position: relative;
      margin: 20px 0px;
      font-size: 16px;
      color: #333333;
      &::before {
        content: '';
        position: absolute;
        left: 0px;
        top: 50%;
        width: 100%;
        height: 0px;
        border-top: 1px solid #e8e8e8;
      }
      .zIndex {
        background-color: #ffffff;
        z-index: 10;
        padding-left: 12px;
        position: relative;
      }
      .addtitle {
        margin: 12px 0px 12px 50%;
        transform: translateX(-50%);
        display: inline-block;
        padding-right: 12px;
      }
    }
    .titleName {
      color: #999999;
      line-height: 26px;
      margin-right: 10px;
    }
  }
  .fieldset {
    padding: 10px 30px;
    border: 1px solid #eeeeee;
    border-radius: 6px;
    position: relative;
    .fieldset-title {
      position: absolute;
      left: 30px;
      top: -14px;
      padding: 2px 10px;
      background-color: #fff;
    }
  }
  .box {
    padding: 0px 30px;
  }
  .inlineBtn {
    white-space: nowrap;
    color: #333333;
    line-height: 30px;
    margin-right: 70px;
    display: inline-block;
    &:hover {
      color: #096dd9;
    }
  }
  .imgArea {
    display: inline-table;
    margin: 0 20px 10px 0px;
    text-align: center;
    position: relative;
    .delBtn {
      position: absolute;
      top: -10px;
      right: -10px;
      cursor: pointer;
      font-size: 20px;
    }
    img {
      width: 84px;
      height: 84px;
      border-radius: 4px;
    }
    p {
      line-height: 30px;
    }
  }
  .icon-bianji {
    margin-right: 10px;
    font-weight: 500;
  }
}
.iconfont:hover {
  color: #3149ef;
}
</style>
